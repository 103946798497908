$black-light: #252525;
$white: #fcfcfc;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  font-family: "Bitter", serif;
  font-weight: 400;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

a,
a:link,
a:active,
a:hover,
a:focus {
  color: $white;
  text-decoration: none;
}

h2 {
  font-size: 3rem;
  line-height: 2.8rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
}

.bold {
  font-weight: bold;
}

.plus {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 1rem;
}

/* MASONRY */

.grid {
  height: 100%;
}

.grid:after {
  content: "";
  display: block;
  clear: both;
}

.grid-sizer,
.grid-item {
  width: 33.333%;
}

.min {
}

.grid-item {
  padding: 1%;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.cover {
  width: 100%;
}

.lil-pics {
  max-height: 7.5vw;
}

/* PHOTOSWIPE */

.pswp__caption__center {
  text-align: center;
  color: $white;
  max-width: 100%;
}

.pswp__button--share {
  display: none;
}

.tags {
  padding: 0.5rem 1rem;
  border: solid 1px $white;
  font-weight: bold;
  margin: 0.5rem;
  opacity: 0.5;
  transition: opacity 1s ease-in-out;
  animation: tags-hide 8s 1 ease-in-out;

  &:hover {
    opacity: 1;
  }
}

@keyframes tags-show {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.pswp__caption {
  min-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pswp__hidden-caption .pswp__caption {
  display: none;
}

@media only screen and (max-width: 700px) {
  .pswp__caption {
    display: none;
  }
}

/* HOME */

.hamburger-menu {
  position: fixed;
  top: 3.5%;
  left: 5%;
  transition: all 1s ease-in-out;
  color: black !important;
  background-color: $white;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &:hover {
    background-color: black;
    color: $white !important;
    animation: hamburger-hover 1s ease-in-out;
  }

  &:link &:focus,
  &:active {
    color: black;
    background-color: $white;
  }
}

.hide {
  opacity: 0;
  left: -3rem;
}

.none {
  display: none !important;
}

.show {
  opacity: 1;
  left: 2rem;
}

@keyframes hamburger-hover {
  from {
    background-color: $white !important;
    color: black;
  }
  to {
    background-color: black !important;
    color: $white;
  }
}

@media only screen and (max-width: 1250px) {
  .hamburger-menu {
    top: 3%;
    left: 2%;
  }
}

nav {
  font-size: 1.5rem;
  margin-top: 5rem;

  a {
    padding: 0.5rem;
    margin: 0.75rem 2rem;
    text-align: center;
    display: block;
    border: solid 1px $white;
    font-weight: bold;
  }

  a:hover {
    animation: menu-anim 0.5s ease-in-out;
    background-color: $white;
    color: black;
  }

  @keyframes menu-anim {
    from {
      background-color: black;
      color: $white;
    }
    to {
      background-color: $white;
      color: black;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  width: 25.1rem;
  max-width: 100%;
  height: 100%;
  background-color: black;
  z-index: 3;
  left: -20%;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  transition: left 400ms ease-in, opacity 400ms ease-in;

  a {
    border: none;
    text-align: initial;
    font-weight: initial;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .menu-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &:target {
    left: 0;
    opacity: 1;
    pointer-events: auto;
  }

  &__photos {
    margin-left: 2rem;
    font-size: 1.1rem;
  }
}

.btn {
  margin: 3rem 0;
  font-family: "Bitter", serif;
  font-weight: 700;
  font-size: 1.7rem;
  border: 1px $white solid;
  padding: 0.5rem 1rem;
  width: max-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .material-icons {
    margin: 0.5rem 0.8rem 0.5rem 0;
  }
}

.shine {
  animation: shine 3s infinite ease-in-out;
}

@keyframes shine {
  0% {
    box-shadow: 0 0 1px white;
  }
  50% {
    box-shadow: 0 0 8px white;
  }
  100% {
    box-shadow: 0 0 1px white;
  }
}

.home {
  height: 100vh;
  display: flex;

  .pannel {
    background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/hero_01_dcd24c6f08.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .titles {
      font-family: "Bitter", serif;
      width: min-content;
      white-space: nowrap;
      color: $white;
      z-index: 2;
      text-transform: initial;
      padding-left: 10%;
      margin-top: 10rem;
      color: white;
      text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.2),
        0px 1px 15px rgba(0, 0, 0, 0.2), 2px 1px 15px rgba(0, 0, 0, 0.2),
        1px 2px 15px rgba(0, 0, 0, 0.2), 3px 2px 15px rgba(0, 0, 0, 0.2),
        2px 3px 15px rgba(0, 0, 0, 0.2), 4px 3px 15px rgba(0, 0, 0, 0.2),
        3px 4px 15px rgba(0, 0, 0, 0.1), 5px 4px 15px rgba(0, 0, 0, 0.1),
        4px 5px 15px rgba(0, 0, 0, 0.1), 6px 5px 15px rgba(0, 0, 0, 0.1),
        0px -1px 15px rgba(0, 0, 0, 0.2), -2px -1px 15px rgba(0, 0, 0, 0.2),
        -1px -2px 15px rgba(0, 0, 0, 0.2), -3px -2px 15px rgba(0, 0, 0, 0.2),
        -2px -3px 15px rgba(0, 0, 0, 0.1), -4px -3px 15px rgba(0, 0, 0, 0.1);
    }

    h1 {
      padding: 0.88rem 1.76rem;
      text-align: center;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 5rem;
    }

    h2 {
      font-weight: 400;
      font-size: 2.5rem;
      text-transform: initial;
      font-style: italic;
      margin-top: 2.2rem;
      margin-left: 5rem;
      line-height: 4.4rem;
    }

    .black-bg {
      padding: 0.22rem 0.88rem;
    }

    nav {
      margin: 15% 0 0 0;
    }

    .main {
      width: 0;
    }
  }
}

.show-responsive {
  display: none;
}

@media only screen and (max-width: 1536px) {
  .home {
    .pannel {
      h1 {
        font-size: 4rem;
      }
      h2 {
        font-size: 2rem;
        line-height: 3.2rem;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .home {
    .pannel {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.5rem;
        line-height: 2.8rem;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .hide-responsive {
    display: none;
  }

  .text {
    .plus-hide {
      display: block;
      color: $white;
      /* transform: scale(0); */
      animation: p-appears 1s 1 ease-in-out;
    }
  }

  @keyframes p-appears {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .show-responsive {
    display: flex;
  }

  .hero {
    margin-top: 2rem;
  }

  .home {
    height: 100%;
    flex-direction: column;
    max-height: initial;

    .pannel {
      background-image: none;
      width: 100%;
      align-items: center;

      nav {
        margin: 3rem 0;
      }

      .titles {
        margin-top: 2rem;
        white-space: unset;
        padding: 0;
      }

      h1 {
        background-image: none;
        padding: 1rem;
        margin: 0;
        font-size: 2.6rem;
        border: none;
      }

      h2 {
        padding: 1rem;
        text-align: center;
        font-size: 1.3rem;
        line-height: 2rem;
        margin-top: 0;
        margin-left: 0;
      }

      .black-bg {
        background-image: none;
        background-color: black;
        padding: 0;
        border: none;
      }
    }
    .main {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .home {
    .pannel {
      .titles {
        max-width: 80%;
        margin: auto;
        margin-top: 2rem;

        br {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .home {
    .pannel {
      h1 {
        font-size: 2rem;
      }
    }
  }

  .section-container {
    h2 {
      font-size: 2rem;
    }
  }
}

.section-container {
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: $white;

  h2 {
    padding: 4rem 3rem;
    width: 100%;
    text-align: center;
  }

  .container {
    display: flex;

    .main {
      padding: 0.7rem 12.6rem 3% 12.6rem;
      min-width: 60%;

      .grid-item {
        img {
          opacity: 1;
          transition: filter 0.6s;
          cursor: pointer;
        }

        img:hover {
          /* opacity: 0.7; */
          filter: brightness(0.7);
        }
      }
    }

    .pannel {
      max-width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        max-width: 100%;
        padding: 0 12.9rem 0 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        p {
          margin: 1rem 0rem;
        }

        .btn {
          text-decoration: none;
        }

        a {
          text-decoration: underline;
          transition: all 0.2s ease-in-out;

          &:hover {
            filter: opacity(0.8);
          }
        }
      }
    }
  }
}

.secoya {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/secoya_bg_e968ce2d37.jpg");
  padding-bottom: 2rem;

  .p-responsive {
    margin: 3rem;
    justify-content: center;
  }

  .btn {
    margin: 1rem 0;
  }

  h3 {
    text-align: center;
    margin: 3rem auto 2rem auto;
  }

  .map {
    max-width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}

.qui {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/qui_bg.jpg");
  padding-bottom: 5rem;

  .container {
    #qui-01 {
      height: 100%;
      width: 100%;
      object-fit: cover;
      margin: auto;
    }

    .profile-pics {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      min-height: 14rem;
      max-width: 100vw;

      .profile-pic {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 3rem;

        img {
          width: 12rem;
          height: 12rem;
          object-fit: cover;
          border-radius: 50%;
          margin: 0 0 1rem 0;
        }

        .subtitle {
          font-style: italic;
          font-size: 1.2rem;
          margin: 0.2rem;
        }
      }
    }
    .pannel {
      min-width: 50%;

      .text {
        padding: 0 0 0 12.6rem;
      }
    }

    .main {
      padding: 0 13rem 0 12.4rem;
      min-width: 30%;
      margin: auto;

      .grid-item {
        padding: 0;
      }
    }
  }
}

.invert {
  .container {
    .pannel {
      .text {
        padding: 0 0 0 12.6rem;
        padding-left: 13rem;
        padding-right: 0.5rem;
      }
    }
  }
}

.minga {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/village_bg_99a3a6227d.jpg");
}

.portraits {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/portraits_bg_78ac979210.jpg");
}

.village {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/village_bg_99a3a6227d.jpg");
}

.art {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/art_bg_3c8be6c4a1.jpg");
}

.foret {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/foret_bg_a35d071ba0.jpg");
}

.eau {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/eau_bg_6bb36d69bb.jpg");
}

@media only screen and (max-width: 1530px) {
  .section-container {
    .container {
      .pannel {
        .text {
          padding: 0 20.5% 0 1.8rem;
        }
      }

      .main {
        padding: 1% 6.5rem 3% 6.5rem;

        .lil-pics {
          max-height: 9vw;
        }
      }
    }
  }

  .invert {
    .container {
      .pannel {
        .text {
          padding: 0 2.5rem 0 20.5%;
        }
      }
    }
  }

  .qui {
    .container {
      .pannel {
        min-width: 51%;
        .text {
          padding-left: 7rem;
          padding-right: 0;
        }
      }
      .main {
        padding: 0 6.9rem 0 9.4rem;
      }
      .profile-pics {
        .profile-pic {
          img {
            width: 10.5rem;
            height: 10.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .invert {
    .container {
      .pannel {
        .text {
          padding: 0 2.5rem 0 21.5%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .section-container {
    .container {
      .main {
        padding: 1% 4rem 3% 6.5rem;

        .lil-pics {
          max-height: 9vw;
        }
      }

      .pannel {
        .text {
          padding-left: 1.3rem;
          padding-right: 6.8rem;

          h2 {
            font-size: 2.4rem;
            $white-space: normal;
            margin-bottom: 1rem;
          }

          p {
            font-size: 1.2rem;
          }

          .btn {
            font-size: 1.5rem;
            margin: 1.5rem 0;
          }
        }
      }
    }
  }

  .invert {
    .container {
      .pannel {
        .text {
          padding-left: 6.8rem;
          padding-right: 1.6rem;
        }
      }

      .main {
        padding-left: 4rem;
        padding-right: 6.8rem;
      }
    }
  }

  .qui {
    .container {
      .pannel {
        min-width: 54%;
        .text {
          padding-left: 7rem;
          padding-right: 0rem;
        }
      }

      .main {
        .profile-pics {
          width: 20%;
          margin: 0 auto 3rem;
          padding-left: 1.5rem;
          .subtitle {
            text-align: center;
            $white-space: nowrap;
          }
          .profile-pic {
            margin: 0.5rem 1.5rem;

            img {
              width: 9rem;
              height: 9rem;
            }
          }
        }

        .grid-item {
          padding-left: 1.6rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .qui {
    padding-bottom: 3rem;
  }
  .section-container {
    .container {
      flex-direction: column;

      .main {
        order: 1;
        width: 100%;
        padding: 1% 1rem 3% 1rem;

        .lil-pics {
          max-height: 16vw;
        }

        .btn {
          margin: 3rem auto;
        }
      }

      .pannel {
        max-width: 100%;
        text-align: center;

        h2 {
          padding: 1rem 0;
        }

        .text {
          padding: 0 20% 2rem 20%;
          align-items: center;

          p {
            text-align: left;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .section-container {
    .container {
      .main {
        width: 100%;
        padding: 0 1% 2rem 1%;
      }

      .pannel {
        .text {
          p:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .qui {
    .container {
      .main {
        .grid-item {
          padding: 0;
        }
      }

      .profile-pics {
        .profile-pic {
          margin: 1rem 1rem;

          img {
            width: 8rem;
            height: 8rem;
            object-fit: cover;
            border-radius: 50%;
            margin: 0 1rem 1rem 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .section-container {
    h2 {
      padding: 2rem 1rem;
    }

    .container {
      .main {
        padding-bottom: 0;

        .lil-pics {
          max-height: 18vw;
        }

        .btn {
          max-width: 80%;
          $white-space: initial;
          text-align: center;
          padding: 0.3rem 0.6rem;
          font-size: 1.4rem;
        }
      }
    }
  }
  .qui {
    .container {
      .main {
        .profile-pics {
          flex-direction: column;
          padding-left: 0;

          .profile-pic {
            margin: 1rem 1rem;

            img {
              width: 8rem;
              height: 8rem;
              object-fit: cover;
              border-radius: 50%;
              margin: 0 1rem 1rem 1rem;
            }
          }
        }
      }
    }
  }
}

.articles {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/articles_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    padding: 5rem 0 2rem 0;
  }

  .blog {
    pointer-events: initial;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;

    .card {
      width: 26%;
      margin: 3rem auto;
      border: solid 1px rgb(94, 94, 94);
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 1s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: 100%;
        height: 15rem;
        object-fit: cover;
      }

      h3 {
        text-align: center;
        margin: 1rem;
      }

      p {
        font-size: 1.2rem;
        padding: 0 3rem;
      }

      .btn {
        margin: 1.5rem;
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .articles {
    .cards {
      .card {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .articles {
    .cards {
      .card {
        width: 40%;
      }

      .card-05,
      .card-06 {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .articles {
    h2 {
      padding: 3rem 0 1rem 0;
    }

    .cards {
      .card {
        width: 60%;
        margin: 2rem auto;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .articles {
    .cards {
      .card {
        width: 100%;
      }
    }
  }
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal {
  position: relative;
  background: black;
  color: $white;
  width: 80%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  overflow-y: scroll;

  .modal-content {
    position: relative;
    margin-top: 50vh;
    p {
      margin: 1rem;
    }
  }

  &__close {
    color: $white;
    font-size: 2rem;
    position: absolute;
    top: 0;
    right: 1rem;
    cursor: pointer;
  }
}

.pswp__caption__center {
  text-align: center;
  font-size: 1rem;
}

.pswp--supports-fs .pswp__button--fs {
  display: none;
}

footer {
  background-image: url("https://secoya-amazonie.s3.eu-west-3.amazonaws.com/foret_bg_a35d071ba0.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;

  .logo-em {
    width: 15rem;
    display: block;
    margin: 3rem auto;
  }

  & > * {
    padding-bottom: 3rem;
  }

  h2 {
    text-align: center;
    padding: 5rem 0;
  }

  p {
    width: 40%;
    margin: auto;
    text-align: center;
  }

  .partners {
    padding-bottom: 0;
  }

  .partners-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: auto;
    column-gap: 1rem;
    width: 60%;
    margin: auto;

    .partner a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 5rem;
      transition: all 0.4s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      width: 15rem;
      margin-bottom: 1rem;
    }
  }

  .press-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: auto;
    column-gap: 1rem;
    width: 50%;
    margin: auto;

    .press-item a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 5rem;

      transition: all 0.4s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      width: 15rem;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 1500px) {
  footer {
    p {
      width: 50%;
      margin: auto;
    }

    .partners-container {
      width: 75%;
    }

    .press-container {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 1250px) {
  footer {
    p {
      width: 70%;
      margin: auto;
    }

    .partners-container {
      width: 85%;
    }

    .press-container {
      width: 85%;
    }
  }
}

@media only screen and (max-width: 960px) {
  footer {
    p {
      width: 60%;
      margin: auto;
    }

    .partners-container {
      grid-template-columns: 49% 49%;
      width: 85%;
    }

    .press-container {
      grid-template-columns: 49% 49%;
      width: 85%;
    }
  }
}

@media only screen and (max-width: 650px) {
  footer {
    p {
      width: 60%;
      margin: auto;
    }

    .partners-container {
      display: flex;
      flex-direction: column;
      width: 85%;
    }

    .press-container {
      display: flex;
      flex-direction: column;
      width: 85%;
    }
  }
}

#minga {
  .btn {
    max-width: 100%;
    text-align: center;

    .material-icons {
      transform: translateY(0.1rem);
    }
  }

  img {
    cursor: default !important;

    &:hover {
      filter: brightness(1) !important;
    }
  }
}

li {
  font-size: 1.2rem;
  margin: 1rem;
  text-align: start;
}
